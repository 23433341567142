@use "../../style/variables.scss" as *;
@use "../../style/mixin.scss" as mixin;

.headers {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  z-index: 200;
  // background: darken($theme-color, 10%);
  background: linear-gradient(
    to left,
    $theme-color,
    lighten($theme-color, 10%),
    $theme-color
  );
  padding: 0 30px;
  @media (max-width: 776px) {
    padding: 0 10px;
  }
  &--normal {
    min-height: 80px;
    transition: min-height 0.5s ease-in-out;
    .header__menu {
      top: 80px;
    }
    .header__logo {
      width: 60px;
      height: 60px;
      transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    }
    .header__wrap {
      width: 60px;
      height: 80px;
      transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    }
  }
  &--short {
    min-height: 55px;
    transition: min-height 0.5s ease-in-out;
    .header__logo {
      width: 45px;
      height: 45px;
      transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    }
    .header__wrap {
      width: 45px;
      height: 55px;
      transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 5px;
  }

  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
  }

  &__img {
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 6px black;
      background-color: rgb(white, 0.2);
    }
    &:active {
      box-shadow: 0 0 10px black;
    }
  }

  &__img2 {
    height: 40px;
    transition: transform 0.3s, opacity 0.3s;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  &__flag {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 20px;
    overflow: hidden;
    color: white;
    font-size: 22px;
    font-weight: bold;
    font-family: "Arial", sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
  }

  // &__flag-img {
  //   position: absolute;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   transform: translate(-50%, -50%);
  //   object-fit: cover;
  //   filter: invert(1);
  //   transition: transform 0.5s ease-in-out;
  // }

  &__menu-flag {
    position: relative;
    padding: 0 10px;
    cursor: pointer;
    margin: 1px 5px;
    border-radius: 7px;
    text-align: center;
    border: 1px solid transparent;

    &:not(.header__btn2):hover {
      .header__menu-span {
        opacity: 1;
        transform: scale(1);
        box-shadow: 0 0 3px $theme-color;
      }
    }

    .header__menu-span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border: 2px solid $theme-color;
      border-radius: 7px;
      transform: scale(0);
      transition: transform 0.3s, opacity 0.3s;
      opacity: 0;
    }
  }
  &__menu {
    position: absolute;
    top: 55px;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 140px;
    padding: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 2px rgba(black, 0.5);
    background-color: white;
    overflow: hidden;
    z-index: 1;
    gap: 15px;
    transition: top 0.5s ease-in-out;
    animation: expHeight 0.2s ease-in-out forwards;
    &--large {
      gap: 5px;
      animation: expHeightLarge 0.2s ease-in-out forwards;
    }

    @keyframes expHeight {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 115px;
        opacity: 1;
      }
    }

    @keyframes expHeightLarge {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 400px;
        opacity: 1;
      }
    }
  }

  &__logo {
    width: 60px;
    transition: width 0.5s ease-in-out;
    background-color: $theme-color3;
    // border: 1px solid rgba(black, 0.2);
    border-radius: 50%;
    box-shadow: 0 0 3px rgba(black, 0.4);
    cursor: pointer;
    &--transparent {
      background-color: transparent;
      @include mixin.button-shake;
    }
  }

  &__back-arrow {
    width: 45px;
    cursor: pointer;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: $theme-color3;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    @include mixin.button;
  }

  &__btn2 {
    text-align: center;
    cursor: pointer;
    width: 110px;
    height: 35px;
    font-weight: bold;
    padding: 5px 10px;
    background-color: $theme-color;
    color: white;
    border-radius: 7px;
    box-shadow: 0 1px 3px black;
    &:hover {
      background-color: darken($theme-color, 5%);
    }
  }
  &__btn-bounce {
    @include mixin.button-bounce;
    margin-right: 15px;
  }
}

.headerNavBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  user-select: none;
  font-size: 30px;
  // text-shadow: 2px 2px 4px rgba($theme-color3, 0.5);
  @media (max-width: 850px) {
    font-size: 20px;
    gap: 5px;
  }
  @media (max-width: 600px) {
    font-size: 15px;
  }

  &__title {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: white;
    font-family: "Oswald" serif;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    @media (max-width: 600px) {
      max-width: 150px;
    }
  }

  &__mirroredArrow {
    display: inline-block;
    transform: scaleX(-1);
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 35px;
    min-height: 35px;
    border-radius: 50%;
    background: transparent;
    border: 3px solid white;
    color: white;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      border: 3px solid $theme-color3;
      color: $theme-color3;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
}

.scrollBtn {
  @include mixin.fixedButton();
  position: fixed;
  right: 20px;
  bottom: 15px;

  display: flex;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
  @media (max-width: 600px) {
    display: none;
  }
  &.hidden {
    opacity: 0;
  }
  &.visible {
    opacity: 1;
  }

  &__arrow {
    position: absolute;
    width: 25px;
    height: 25px;
    border-left: none;
    border-top: none;
    border-right: 2px #fff solid;
    border-bottom: 2px #fff solid;
    transform: rotate(-135deg);
    transition: transform 1s ease;
  }
}

.menu-enter {
  opacity: 0;
  transform: scaleY(0);
}
.menu-enter-active {
  opacity: 1;
  transform: scaleY(1);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.menu-exit {
  opacity: 1;
  transform: scaleY(1);
}
.menu-exit-active {
  opacity: 0;
  transform: scaleY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

// .search {
//   display: flex;
//   margin: 10px;
//   @media (max-width: 600px) {
//     display: none;
//   }
//   &__container {
//     position: relative;
//   }
//   &__input {
//     padding: 8px 30px 8px 8px; // Збільшимо правий внутрішній відступ
//     width: 300px;
//     border: none;
//     border-radius: 4px;
//     outline: none;
//     &:focus {
//       box-shadow: 0 0 3px black;
//     }
//   }
//   &__btn {
//     background-image: url("../../lib/img/Magnifying.png");
//     background-size: cover;
//     background-color: transparent;
//     width: 24px; // Зменшимо ширину кнопки
//     height: 24px; // Задамо висоту кнопки
//     position: absolute;
//     top: 50%;
//     right: 5px;
//     transform: translateY(-50%);
//     cursor: pointer;
//     border: none;
//     outline: none;
//   }
// }
