@use "./variables.scss" as *;
$globalOpacity: 0.7;

@mixin articleTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  font-family: "Oswald", sans-serif;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: $theme-color;
  &:after {
    content: "";
    display: block;
    width: 90%;
    margin: 0 auto;
    border-bottom: 3px solid $theme-color;
  }
  @media (max-width: 500px) {
    font-size: 19px;
  }
}

@mixin fixedButton {
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba($theme-color, $globalOpacity);
  box-shadow: 0px 0px 10px rgba($theme-color, $globalOpacity);
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 12px rgba($theme-color, 0.8);
  }
  &:active {
    box-shadow: 0px 0px 15px rgba($theme-color, 1);
  }
}

@mixin cross {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 16px;
  color: $theme-color;
  transition: transform 0.1s ease-in-out;
  background: transparent;
  transform: scale(1.2);
  cursor: pointer;
  z-index: 5;
  opacity: 1;
  &:hover {
    transform: scale(1.3);
    // background-color: rgba($theme-color, 0.7);
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@mixin spinner {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 10px solid transparent;
  border-top-color: $theme-color;
  animation: spin 0.8s linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

@mixin button {
  position: relative;
  display: inline-block;
  height: 35px;
  padding: 10px 5px;
  margin: 0 23px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  background-color: $theme-color;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: $theme-color2;
    // color: $theme-color;
    &:before {
      border-color: transparent $theme-color2 transparent transparent;
    }
    &:after {
      border-color: transparent transparent transparent $theme-color2;
    }
  }
  &:active {
    transform: scale(0.95);
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    transition: border-width 0.3s ease, border-color 0.3s ease;
  }
  &:before {
    left: -19px;
    border-width: 25px 25px 25px 0;
    border-color: transparent $theme-color transparent transparent;
    transform: rotate(225deg) translateY(-2px);
  }
  &:after {
    right: -5px;
    border-width: 25px 0 25px 25px;
    border-color: transparent transparent transparent $theme-color;
    transform: rotate(225deg) translateY(23px);
  }
}

@mixin button2 {
  display: flex;
  justify-content: flex-start;
  font-size: 17px;
  padding: 10px;
  border-radius: 8px;
  background: linear-gradient(145deg, #e9e9e9, #ffffff);
  transition: all 0.3s ease;
  gap: 10px;
  &:hover {
    background: linear-gradient(145deg, lighten($theme-color, 35%), #ffffff);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &--exitTab {
    &:hover {
      background: linear-gradient(145deg, rgb(255, 127, 127), #ffffff);
    }
  }
  &--active {
    // background-color: $theme-gray;
    background: linear-gradient(145deg, lighten($theme-color, 35%), #ffffff);
  }
  &--disactive {
    position: relative;
    &:hover {
      background: linear-gradient(145deg, #ff9c08, #ffffff);
      box-shadow: unset;
      cursor: unset;
    }
  }
}

@mixin button3 {
  display: flex;
  justify-content: center;
  font-size: 17px;
  padding: 10px;
  border-radius: 8px;
  background: linear-gradient(145deg, lighten($theme-color, 35%), #ffffff);
  transition: all 0.4s ease-in-out;
  gap: 10px;
  &:hover {
    background: lighten($theme-color, 35%);
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
}

@mixin text-input {
  width: 100%;
  height: 40px;
  text-align: center;
  letter-spacing: 1px;
  border-radius: 7px;
  outline: none;
  box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.4);
  &::placeholder {
    letter-spacing: 1px;
    font-style: italic;
    color: grey;
  }
}

@mixin tab {
  position: relative;
  display: inline-block;
  height: 35px;
  padding: 10px 5px;
  margin: 0 15px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  background-color: $theme-color;
  transition: all 0.2s ease;
  cursor: pointer;
  @media (max-width: 500px) {
    padding: 5px 1px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    transition: border-width 0.3s ease, border-color 0.3s ease;
  }
  &:before {
    left: -19px;
    border-width: 25px 25px 25px 0;
    border-color: transparent $theme-color transparent transparent;
    transform: rotate(225deg) translateY(-2px);
  }
  &:after {
    right: -19px;
    border-width: 25px 0 25px 25px;
    border-color: transparent transparent transparent $theme-color;
    transform: rotate(135deg) translateY(-2px);
  }

  &:hover {
    z-index: 1;
    background-color: $theme-color2;
    color: white;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      transition: border-width 0.3s ease, border-color 0.3s ease;
    }
    &:before {
      left: -19px;
      border-width: 25px 25px 25px 0;
      border-color: transparent $theme-color2 transparent transparent;
      transform: rotate(225deg) translateY(-2px);
    }
    &:after {
      right: -19px;
      border-width: 25px 0 25px 25px;
      border-color: transparent transparent transparent $theme-color2;
      transform: rotate(135deg) translateY(-2px);
    }
  }
  &:active {
    transform: scale(0.95);
  }
}

@mixin tab2 {
  position: relative;
  display: inline-block;
  height: 35px;
  padding: 10px 5px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  user-select: none;
  transition: all 0.2s ease;
  cursor: pointer;
  @media (max-width: 500px) {
    padding: 5px 1px;
  }

  &:hover {
    z-index: 1;
    background-color: white;
    color: $theme-color;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      transition: border-width 0.3s ease, border-color 0.3s ease;
    }
    &:before {
      left: -19px;
      border-width: 25px 25px 25px 0;
      border-color: transparent white transparent transparent;
      transform: rotate(225deg) translateY(-2px);
    }
    &:after {
      right: -19px;
      border-width: 25px 0 25px 25px;
      border-color: transparent transparent transparent white;
      transform: rotate(135deg) translateY(-2px);
    }
  }
  // &:active {
  //   transform: scale(1.01);
  // }
}

@mixin button-shake {
  &:hover {
    animation: shake 1s ease-in-out infinite;
  }
}

@mixin button-bounce {
  &:hover {
    animation: bounce 1s ease-in-out infinite;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(3deg);
  }
}
