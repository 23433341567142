@use "../../style/variables.scss" as *;
@use "../../style/mixin.scss" as mixin;

.subHeader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", serif;
  color: white;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(black, 0.6),
    rgba(black, 0.4),
    rgba(black, 0.6)
  );
  @media (max-width: 930px) {
    display: none;
  }
  &--visible {
    height: 35px;
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
  &--hidden {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 1400px;
    width: 100%;
  }

  &__row {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__title {
    text-align: center;
    text-decoration: underline;
    font-size: 17px;
    margin-bottom: 8px;
  }

  &__item {
    @include mixin.tab2;
    font-size: 15px;
    line-height: 1.3;
    // text-shadow: 1px 1px 7px rgba(white, 0.8);

    &--active {
      background-color: white;
      color: $theme-color;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        transition: border-width 0.3s ease, border-color 0.3s ease;
      }
      &::before {
        left: -19px;
        border-width: 25px 25px 25px 0;
        border-color: transparent white transparent transparent;
        transform: rotate(225deg) translateY(-2px);
      }
      &::after {
        right: -19px;
        border-width: 25px 0 25px 25px;
        border-color: transparent transparent transparent white;
        transform: rotate(135deg) translateY(-2px);
      }
    }

    @media (max-width: 776px) {
      font-size: 13px;
      margin: 0 7px;
    }
  }
}

.subHeaderModal {
  position: absolute;
  // left: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  max-width: 700px;
  width: fit-content;
  color: black;
  background-color: white;
  font-family: "Roboto", serif;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  // box-shadow: 0 0 5px rgba(white, 0.8);
  overflow: hidden;
  padding: 15px;

  &__container {
    display: flex;
    justify-content: space-around;
    width: fit-content;
    line-height: 1.5;
    &--180 {
      animation: expandHeight180 0.2s ease-out forwards;
    }
    &--140 {
      animation: expandHeight140 0.2s ease-out forwards;
    }
    &--130 {
      animation: expandHeight130 0.2s ease-out forwards;
    }
    &--110 {
      animation: expandHeight110 0.2s ease-out forwards;
    }
    &--80 {
      animation: expandHeight80 0.2s ease-out forwards;
    }
    &--70 {
      animation: expandHeight70 0.2s ease-out forwards;
    }
    &--60 {
      animation: expandHeight60 0.2s ease-out forwards;
    }
    &--50 {
      animation: expandHeight50 0.2s ease-out forwards;
    }
    &--30 {
      animation: expandHeight30 0.2s ease-out forwards;
    }

    @keyframes expandHeight180 {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 180px;
        opacity: 1;
      }
    }

    @keyframes expandHeight140 {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 140px;
        opacity: 1;
      }
    }

    @keyframes expandHeight130 {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 130px;
        opacity: 1;
      }
    }

    @keyframes expandHeight110 {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 110px;
        opacity: 1;
      }
    }

    @keyframes expandHeight80 {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 80px;
        opacity: 1;
      }
    }

    @keyframes expandHeight70 {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 70px;
        opacity: 1;
      }
    }

    @keyframes expandHeight60 {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 60px;
        opacity: 1;
      }
    }

    @keyframes expandHeight50 {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 50px;
        opacity: 1;
      }
    }

    @keyframes expandHeight30 {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 30px;
        opacity: 1;
      }
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    max-width: 320px;
  }

  &__col-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  &__title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Montserrat", serif;
    color: $theme-color;
    text-align: center;
    font-size: 15px;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 16px;
    color: #333333;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }

  &__row-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__item {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "Roboto", serif;
    transition: transform 0.3s ease;
    cursor: pointer;
    &::marker {
      content: "\2713";
      color: $theme-color;
      font-weight: bold;
    }

    &:hover {
      color: $theme-color;
      transform: scale(1.03);
      // text-decoration: underline;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 1em;
    }
  }

  &__btn {
    color: blue;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: $theme-color;
    }

    &--link {
      color: black;
    }
  }

  &__icon {
    display: block;
    width: 25px;
    height: 25px;
    margin-right: 5px;
    transition: scale 0.3s ease, opacity 0.3s ease;

    &:hover {
      cursor: pointer;
      opacity: 1;
      scale: 1.1;
    }
  }

  &__label {
    font-weight: bold;
  }
}
