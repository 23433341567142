@use "../../style/variables.scss" as *;
@use "../../style/mixin.scss" as mixin;

.footer {
  display: flex;
  // height: 200px;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  padding: 15px 10px 0 10px;
  background: linear-gradient(135deg, #000000, #0d1b2a);
  font-family: "Poppins", sans-serif;
  color: white;
  gap: 10px;
  @media (max-width: 1117px) {
    justify-content: center;
  }

  &__title {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 5px;
    text-align: center;
    color: $theme-color;
    text-shadow: 2px 2px 4px rgba($theme-color, 0.5);
  }

  &__text {
    line-height: 1.6;
    text-align: left;
    text-decoration: none;
    color: white;
    &--mod {
      color: white;
      margin: 2.5px;
      font-weight: 500;
    }

    &--btn {
      &:hover {
        cursor: pointer;
        color: lighten($theme-color, 10%);
      }
    }

    &--underline {
      text-decoration: underline;
    }
  }

  &__link {
    // color: #b0b0b0;
    color: white;
    line-height: 1.6;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: lighten($theme-color, 10%);
    }
  }

  &__linkwrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 20px;
    gap: 15px;
    @media (max-width: 900px) {
      margin: 0 10px;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &:hover {
      > img {
        transform: scale(1.1);
      }
    }
    &.left {
      width: fit-content;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    max-width: 300px;
  }

  &__icon {
    display: block;
    width: 35px;
    height: auto;
    // margin: 5px;
    transition: transform 0.3s ease, filter 0.3s ease;
    &:hover {
      cursor: pointer;
      // filter: brightness(1.5);
      transform: scale(1.1);
    }

    &.big {
      width: 40px;
    }

    &--invert {
      filter: invert(1);
      // &:hover {
      //   filter: invert(1), brightness(1.5);
      // }
    }
  }

  &__label {
    font-weight: bold;
  }

  &__divider {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      transparent,
      white,
      transparent
    );
  }

  &__copy {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 10px;
    font-size: 0.875em;
    color: #a1a1a1;
  }
}

.support-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background: #007bff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    background: #0056b3;
  }

  .chat-icon {
    position: relative;
    width: 18px;
    height: 16px;
    background: white;
    border-radius: 6px;

    &::after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 4px;
      width: 8px;
      height: 8px;
      background: white;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
    }
  }
}
